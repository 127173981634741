<img src="/assets/img/players/spaceshipgirl.png" style="float:right;margin-left:20px" class="desktop-only"/>
<h1>Need Help?</h1>
<div>
    <p>
        VMK Legacy has a team of volunteer staff members who moderate the game and provide support. There are multiple
        ways to get help:
    </p>
    <ul>
        <li>Press <b>HELP</b> in the game toolbar to send a <b>Call for Help</b>.</li>
        <li>Join the <a href="https://discord.gg/8RQe3DBRNS" target="_blank">Discord server</a> to ask other players,
            or reach out to a staff member with a <b>VMK Legacy Staff</b> role.
        </li>
        <li>Message <b style="font-family: monospace">help</b> to the <b>VMK Legacy</b> bot in the <a
                href="https://discord.gg/8RQe3DBRNS"
                target="_blank">Discord server</a>.
        </li>
        <li>Send an email to <a href="mailto:contact@vmklegacy.com"><b>contact&#64;vmklegacy.com</b></a>.</li>
    </ul>
</div>
<h2 style="margin-top: 30px">Why is the game closed?</h2>
<p>
    Just like original VMK, Legacy is closed during the night. Our typical hours are 9 AM - 1 AM Eastern, with extended hours
    during some special holidays. Check the homepage for today's hours.
</p>
<h2 style="margin-top: 30px">Troubleshooting</h2>
<p>
    Experiencing a glitch or buggy behavior? Try these things first:
</p>
<ul>
    <li>Refresh the page.</li>
    <li>Clear the browser's cache.</li>
    <li>Try disabling any browser extensions or plugins.</li>
    <li>Make sure your device and web browser are up to date.</li>
    <li>After you've tried the above steps, submit a <a
            href="https://www2.vmklegacy.com/bug-report"
            target="_blank"><b>bug report</b></a> to alert our developers to it.
    </li>
</ul>

<h2 style="margin-top: 30px">System Requirements</h2>
<p>
    VMK Legacy is created using the latest web features, so we generally only support browsers released in the past few
    years. Recommended system requirements:
</p>
<ul>
    <li>Chrome 119 or later</li>
    <li>Edge 119 or later</li>
    <li>Firefox 115 or later</li>
    <li>Opera 101 or later</li>
    <li>Safari (Mac) 16.1 or later</li>
    <li>iOS / iPadOS 15.0 or later (any browser)</li>
    <li>Samsung Internet 22 or later</li>
</ul>