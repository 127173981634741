import { Component } from '@angular/core'
import { environment } from '../../../environments/environment'
import { CookieService } from 'ngx-cookie-service'

@Component({
    selector: 'app-screenshot-slider',
    templateUrl: './screenshot-slider.component.html',
    styleUrls: ['./screenshot-slider.component.scss']
})
export class ScreenshotSliderComponent {
    files: string[]
    currentIndex = 0
    clientUrl = environment.CLIENT_URL
    nextTimer?: any
    
    constructor(private cookieService: CookieService) {
        this.files = ['lop-afterparty', 'lop-pirates', 'scr6', 'scr11',
            'club33', 'grogumaze1', 'hostevent', 'hostevent2', 'madhater', 'vjblueroom', 'waterparkpool']
        this.files.sort(() => 0.5 - Math.random())
        this.setNextTimer()
    }
    
    asIsSort(): number {
        return 0
    }
    
    setNextTimer(): void {
        clearTimeout(this.nextTimer)
    
        this.nextTimer = setTimeout(() => {
            this.nextImage()
        }, 7000)
    }

    nextImage(): void {
        if (this.currentIndex === this.files.length - 1) {
            this.currentIndex = 0
        } else {
            this.currentIndex++
        }

        this.setNextTimer()
    }

    backImage(): void {
        if (this.currentIndex === 0) {
            this.currentIndex = this.files.length - 1
        } else {
            this.currentIndex--
        }
        this.setNextTimer()
    }
}