<div *ngIf="loadError">
    <h2>Page Not Found</h2>
    {{ loadError }}
</div>
<div *ngIf="!loadError">
    <div *ngIf="page">
        <div>
            <h1 *ngIf="page?.subjectItem" class="text-center">{{ page?.title ?? "Loading page..." }}</h1>
            <div>
                <a [routerLink]="backPath" [queryParams]="backParams" *ngIf="backPath">« Back to Search</a>
                <a [routerLink]="'/kb'" *ngIf="!backPath && page?.subjectItem">« Item Search</a>
            </div>
            <div *ngIf="page?.subjectItem" class="row">
                <div class="col-6">
                    <img [src]="environment.THUMBS_URL + '/item_' + page?.subjectItem?.uid + '.png'"
                         draggable="false"
                         class="kb-item-img-lg"/>
                </div>
                <div class="col-6">
                    <table class="text-left" *ngIf="page?.subjectItem?.attributes">
                        <tr *ngFor="let attribute of page?.subjectItem?.attributes | keyvalue">
                            <th>{{ attribute.key }}</th>
                            <td>{{ attribute.value }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div *ngIf="pageBody" style="display: flex;gap: 15px;">
                <div *ngIf="page?.category" class="kb-sidebar">
                    <div>
                        <b>Knowledge Base</b>
                        <ul style="padding-left: 15px">
                            <li><a [routerLink]="page?.category?.path">{{ page?.category?.name }}</a>
                                <ul *ngIf="page?.category?.siblings" style="padding-left: 15px">
                                    <li *ngFor="let sibling of page?.category?.siblings"><a [routerLink]="sibling.path">{{ sibling.name }}</a></li>
                                </ul>
                                <ul *ngIf="!page?.category?.siblings?.length">
                                    <li>{{ page?.title }}</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div style="flex-shrink:2">
                    <h1 *ngIf="!page?.subjectItem" class="text-center">{{ page?.title ?? "Loading page..." }}</h1>
                    <div *ngIf="page?.category" class="kb-breadcrumbs">
                        Knowledge Base » <a [routerLink]="page?.category?.path">{{ page?.category?.name }}</a> » {{ page?.title }}
                    </div>
                    <div [innerHTML]="pageBody">
                    </div>
                </div>
            </div>
            <div *ngIf="page?.subjectItem?.releases" class="text-center">
                <h3>Releases</h3>
                <table class="text-left" style="margin: 0 auto">
                    <tr>
                        <th></th>
                        <th>Area</th>
                        <th>Date</th>
                    </tr>
                    <tr *ngFor="let release of normalizedReleases">
                        <td>
                            <span *ngIf="release.type === 'shop'">🛒</span>
                            <span *ngIf="release.type === 'code'">#️⃣</span>
                            <span *ngIf="release.type === 'drop'">🪄</span>
                            <span *ngIf="release.type === 'event'">📆</span>
                        </td>
                        <td>{{ release.description }}{{ release.type === 'shop' && !release.description.includes('Shop') ? ' Shop' : '' }}</td>
                        <td>{{ getTimeString(release) }}</td>
                    </tr>
                </table>
                <p>
                    <small><i>Let us know if you see an inaccuracy or omission in release data.</i></small>
                </p>
            </div>
        </div>
    </div>
    <div *ngIf="!page" class="page-loading">
        <b>Loading page...</b>
    </div>
</div>