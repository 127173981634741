<div style="padding: 0 5%;padding-bottom:20px;display:flex;gap:20px;justify-content: center;align-items:center">
    <img src="/assets/img/players/spaceshipgirl.png" class="desktop-only"/>
    <div style="margin:0;padding:0;text-align: center;font-size:18px;line-height:1.5em">
        <div id="logo-welcome">
        <b style="font-size:30px;white-space: nowrap">Welcome to</b><img src="assets/img/LogoType.svg"
                                                  alt="VMK Legacy"
                                                  style="width: 275px;max-height: 100%;vertical-align:middle"
        /></div><br/>
        A fan re-creation of the <b>Virtual Magic Kingdom</b> game, a
        virtual world inspired by Disney theme parks. VMKL is organized by
        fans and is 100% free to play.<br/><br/>Join us today as we relive the magic!
    </div>
</div>
<div class="mobile-only">
    <app-sidebar></app-sidebar>
</div>
<app-latest-news></app-latest-news>
<div style="border-radius: 7px 25px; max-height: max(200px, min(300px, 25vh));overflow:hidden;display:flex;align-items: center">
    <img src="assets/img/bazaarwave.jpg" style="max-width:100%">
</div>
<div id="explainers" class="bg-darkblue">
    <div class="info-item">
        <div class="img">
            <img src="/assets/img/midien.png">
        </div>
        <div class="info">
            <h2>Create a character</h2>
            <p>
                Customize your in-game character with a variety of clothing items, appearances, and
                hairstyles.
                Build up your wardrobe to show off your own unique style.
            </p>
        </div>
    </div>
    <div class="info-item">
        <div class="img">
            <img src="/assets/img/esmeralda0024.png">
        </div>
        <div class="info">
            <h2>Make Friends</h2>
            <p>
                We have a community of players from all walks of life, all over the world.
                Meet someone new at one of our regular events, or walking around the Kingdom.
            </p>
        </div>
    </div>
    <div class="info-item">
        <div class="img">
            <img src="/assets/img/items/item_thumb_110.png">
        </div>
        <div class="info">
            <h2>Build Rooms</h2>
            <p>
                Tired after a long day of exploring the Kingdom? Retire to your own Guest Room,
                decorated to your liking. Create themed rooms, works of art, and even build
                your own rides for others to enjoy!
            </p>
        </div>
    </div>
    <div class="info-item">
        <div class="img">
            <img src="/assets/img/flamingo_pose_2.png">
        </div>
        <div class="info">
            <h2>Play Minigames</h2>
            <p>
                Budding photographers head on over to the Jungle Cruise Photo Safari to snap
                photos of some of the local animals. If you're skilled enough, you'll earn
                some credits!
            </p>
        </div>
    </div>
</div>
<div class="info-item bg-purple">
    <div class="info">
        <h2>Made For Modern Devices</h2>
        <p>
            VMK Legacy was built for the web browsers and devices of today, Shockwave not required!
        </p>
        <div class="multiple-imgs">
            <img src="/assets/img/map-browser-screenshot.png">
            <img src="/assets/img/yeti_talkie_0006.png" style="width: initial">
            <img src="/assets/img/ipad-screenshot.png">
        </div>
    </div>
</div>

<div style="height: 125px;position:relative;overflow:hidden;">
    <div id="room-previews">
        <div style="background-image:url(/assets/img/rooms/tron_private_bg.png)">
            <div>Tron Guest Room</div>
        </div>
        <div style="background-image:url(/assets/img/rooms/musical_alternate_bg.png)">
            <div>Main Street</div>
        </div>
        <div style="background-image:url(/assets/img/rooms/treasure_bg.png)">
            <div>Treasure Room</div>
        </div>
        <div style="background-image:url(/assets/img/rooms/musicmix.jpg)">
            <div>Street Party Music Game</div>
        </div>
        <div style="background-image:url(/assets/img/rooms/thundermountain_bg.png)">
            <div>Big Thunder Mountain</div>
        </div>
        <div style="background-image:url(/assets/img/rooms/storybookland_bg.png)">
            <div>Storybookland</div>
        </div>
    </div>
</div>
<div class="info-item bg-darkblue">
    <div class="img">
        <img src="/assets/img/staffplayer.png">
    </div>
    <div class="info">
        <h2>Your Safety is Important</h2>
        <p>
            Whether this is your first time online or you're a seasoned visitor, we require respect and good
            manners. Our moderators are just a "Call for Help" away!
        </p>
        <a routerLink="/rules" class="btn bg-white">Read our Rules</a>
    </div>
</div>
<div class="info-item bg-pink">
    <div class="img">
        <img src="/assets/img/items/item_thumb_7.png">
    </div>
    <div class="info">
        <h2>We're on Discord</h2>
        <p>
            The VMK Legacy community has a Discord server! Come join us for quick updates about the game and
            chat with your VMK buddies when the game is closed.
        </p>
        <a href="https://discord.gg/8RQe3DBRNS" target="_blank" class="btn bg-white">Join Our Discord</a>
    </div>
</div>
<router-outlet style="position: absolute"></router-outlet>
